import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {layoutOptions} from "../components/Content/RichText";
import { Helmet } from "react-helmet";
import Img from 'gatsby-image';
import Layout from "../components/layout";
import moment from 'moment';
import style from "../components/News/News.module.css";

const NewsPostContent = ({data, location, ...props}) => {
    const { slug, publishDate, byLine, sourceUrl, title, copy, image, topic } = data.contentfulNewsPost;
    const content = data.contentfulNewsPost;
    const richText = documentToReactComponents(content.content.json, layoutOptions);
    const titleNews = `${title}`;
    const url = `https://www.bettertradeoff.com/news/article/${slug}`
    const description = copy.copy

    return (
        <Layout location={location}>
        <Helmet>
            <title>{titleNews}</title>
            <meta name="image" content={`https:${image.resize.src}`} />
            <meta name="url" content={url} />
            <meta name="description" content={description} />

            <meta property="og:title" content={titleNews} />
            <meta property="og:image" content={`https:${image.resize.src}`} />
            <meta property="og:url" content={url} />
            <meta property="og:description" content={description} />

            <meta name="twitter:title" content={titleNews} />
            <meta name="twitter:image" content={`https:${image.resize.src}`} />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:description" content={description} />
            <link rel="canonical" href={`https://www.bettertradeoff.com/news/press/${slug}`} />


            <script async src="//cdn.embedly.com/widgets/platform.js" charset="UTF-8"></script>
        </Helmet>

        <section className="uk-section uk-section-small">
            <div className={`uk-container uk-container-small`}>
                <h1 className={`${style.postHeader} uk-margin-remove-adjacent uk-margin-remove-top`}>
                    {title}
                </h1>
                <small className="uk-text-muted">
                    Posted on {moment(publishDate).format('MMM D, YYYY')} by <a style={{color: "#4a4a4a", textDecoration: "underline"}} href={sourceUrl} target="_blank" rel="noreferrer">{byLine}</a>
                </small>
            </div>
        </section>
        <section className="uk-section uk-section-small">
            { image ? (
                <div className="uk-container uk-section uk-padding-small">
                    <div className="uk-position-relative">
                        <Img
                            className={style.blogheroImage}
                            fluid={image.fluid}
                            alt={image.title}
                            objectFit="cover"
                            objectPosition="50% 50%"
                             />
                    </div>
                </div>
            ) : null}
        </section>

        <div className={`${style.postContent}`}>
            {richText}
        </div>
        </Layout>
    );
};

export default NewsPostContent;
export const pressPostQuery = graphql`
    query pressPostQuery($slug: String) {
      contentfulNewsPost(slug: { eq: $slug }) {
        title
        publishDate
        byLine
        sourceUrl
        copy {
          copy
          childMarkdownRemark {
            html
          }
        }
        content {
          json
        }
        image {
            resize(width: 1600, quality: 100) {
                src
            }
            fluid(maxWidth: 1600) {
                ...GatsbyContentfulFluid_withWebp
            }
          title
          localFile {
            publicURL
            url
          }
        }
        slug
      }
    }`
